<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="
            handleSubmit(
              dataReceived.title == 'Add New User'
                ? addNewUser
                : updateExistingUser
            )
          "
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">{{ dataReceived.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Name"
                    :rules="{
                      required: true,
                      min: 6,
                      regex: /^[a-zA-Z][a-zA-Z0-9.,$;].+$/,
                    }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="User Name"
                      v-model="userData.userName"
                      class="form-control"
                      placeholder="User Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account"
                      :disabled="
                        dataReceived.title === 'Update User' || loading
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Email"
                      v-model="userData.emailId"
                      class="form-control"
                      placeholder="Email"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-at"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    dataReceived.title == 'Add New User' || roleName === 'Admin'
                  "
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    :rules="{
                      required:
                        dataReceived.title === 'Update User' ? false : true,
                      min: 8,
                      regex:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                    }"
                    vid="confirm"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Password"
                      v-model="userData.password"
                      class="form-control"
                      placeholder="Password"
                      outlined
                      :error-messages="
                        errors.includes('Password is not valid.')
                          ? 'Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.'
                          : errors
                      "
                      prepend-icon="mdi-lock"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    dataReceived.title == 'Add New User' || roleName === 'Admin'
                  "
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    :rules="{
                      required:
                        dataReceived.title === 'Update User' ? false : true,
                    }"
                  >
                    <v-text-field
                      hint="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      label="Confirm Password"
                      v-model="userData.confirmPassword"
                      class="form-control"
                      placeholder="Confirm Password"
                      required
                      outlined
                      :type="showPassword ? 'text' : 'password'"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Address"
                      v-model="userData.address"
                      class="form-control"
                      placeholder="Address"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-map-marker"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    :rules="{
                      regex: /^(\d{3}-)\d{3}-\d{4}$/,
                    }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Phone Number"
                      class="form-control"
                      placeholder="Phone Number"
                      required
                      outlined
                      hide-details=""
                      :error-messages="errors"
                      prepend-icon="mdi-phone"
                      :disabled="loading"
                      :value="acceptNumber(userData.contactNo)"
                      @keypress="onlyNumbers"
                      @input="userData.contactNo = $event.replaceAll('-', '')"
                      hint="The Phone format is XXX-XXX-XXXX"
                      maxlength="12"
                      persistent-hint
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Type"
                    mode="lazy"
                  >
                    <v-select
                      v-model="userData.rolename"
                      :items="roles"
                      item-text="roleName"
                      item-value="roleId"
                      label="User Type"
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account-box-multiple-outline"
                      :disabled="(!roleAdmin && !roleAction) || loading"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider name="Active Event" mode="lazy">
                    <v-checkbox
                      label="Active User"
                      hide-details
                      class="mt-n2"
                      v-model="userData.isActive"
                      :disabled="loading"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="resetForm(), $emit('disableDialog', false)"
            >
              Close
            </v-btn>
            <v-btn
              v-if="dataReceived.title == 'Add New User'"
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import UserService from "../../services/UserService.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  watch: {
    dataReceived: {
      handler: function () {
        if (this.dataReceived.title === "Update User") {
          this.userData.userId = this.dataReceived.userId;
          this.userData.address = this.dataReceived.address;
          this.userData.emailId = this.dataReceived.email;
          this.userData.isActive = this.dataReceived.isActive;
          this.userData.password = this.dataReceived.password;
          this.userData.confirmPassword = this.dataReceived.password;
          this.userData.contactNo = this.dataReceived.contactNo;
          this.userData.userName = this.dataReceived.userName;
          this.userData.rolename = this.dataReceived.roleId || 1;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    const response = await UserService.getRolesList();
    this.roles = response.data.data;
    this.roleName = this.$store.state.auth.userState.roleName;
    // await console.log(this.roleName);
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },
  computed: {
    roleAdmin() {
      return (
        this.dataReceived.userName !== this.$store.state.auth.userState.userName
      );
    },
    roleAction() {
      return this.dataReceived.title == "Add New User" ? true : false;
    },
  },
  data: () => ({
    roleName: "",
    userData: {
      userId: 0,
      userName: "",
      password: "",
      emailId: "",
      contactNo: "",
      confirmPassword: "",
      isActive: true,
      address: "",
      roleId: 1,
    },
    roles: [],
    rolename: 3,
    showPassword: false,
    loading: false,
  }),

  methods: {
    async addNewUser() {
      try {
        this.loading = true;
        const response = await UserService.addUser({
          // id: 0,
          userName: this.userData.userName,
          password: this.userData.password,
          email: this.userData.emailId,
          contactNo: this.userData.contactNo || "",
          address: this.userData.address || "",
          roleId: this.userData.rolename,
          isActive: this.userData.isActive,
        });

        if (!response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "User Added Successfully",
              type: "success",
            },
            { root: true }
          );

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.$emit("reloadUser");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async updateExistingUser() {
      try {
        this.loading = true;

        const response = await UserService.updateUser({
          userId: this.userData.userId,
          // userName: this.userData.userName,
          password: this.userData.password,
          email: this.userData.emailId,
          contactNo: this.userData.contactNo,
          address: this.userData.address,
          isActive: this.userData.isActive,
          roleId: this.userData.rolename ? this.userData.rolename : 0,
        });
        if (!response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "User Update Successfully",
              type: "success",
            },
            { root: true }
          );

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.$emit("reloadUser");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();
      this.userData.userName = "";
      this.userData.password = "";
      this.userData.emailId = "";
      this.userData.contactNo = "";
      this.userData.address = "";
      this.userData.roleId = 1;
      this.userData.isActive = true;
    },
  },
};
</script>
